<template>
   <div class="product__item white-bg h-100" :class="view == 'Carousel' ? 'carousel-250' : ''">
      <div class="product__thumb fix p-relative">
         <router-link :to="'/produto/'+ produto.codigo" class="w-img">
            <img :src="produto.foto == null ? '' : produto.foto" alt="produto" @error="imageError">
         </router-link>
         <div class="product__action p-absolute">
            <ul class="d-flex justify-content-end">
               <li>
                  <a href="javascript:;" @click="comparar">
                     <i class="far fa-check" title="Remover da lista de comparação" v-if="codigosComparacao.includes(produto.codigo)"></i>
                     <i class="far fa-random" title="Adicionar a lista de comparação" v-else></i>
                  </a>
               </li>
            </ul>
         </div>
      </div>
      <div class="product__content text-center">
         <h1 class="product-name mt-2 mb-2 mb-xl-1">
            <router-link :to="'/produto/'+ produto.codigo" class="product-item-link">{{ produto.nome }}</router-link>
         </h1>
         <div class="rating">Cód. {{ String(produto.codigo).substring(0, 2) +"-"+ String(produto.codigo).substring(2, String(produto.codigo).length) }}</div>
         <p class="description mb-1 d-none d-xl-flex">
            <small class="limitador-3 font-13">{{ produto.descricao == null || String(produto.descricao).trim() == '' ? 'Sem descrição' : produto.descricao }}</small>
         </p>
         <span class="price d-block" v-if="logado.cliente">R$ {{ produto.preco == null ? '-' : parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
      </div>
      <div class="product__add-btn" v-if="logado.cliente && produto.preco != null">
         <button type="button" @click="addCarrinho"><i class="far fa-shopping-cart me-1"></i> Carrinho</button>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Produto',
   props: ['produto', 'view'],
   computed: {
		... mapState({
			codigosComparacao: state => state.codigosComparacao,
			logado: state => state.logado
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      comparar : function () {
         this.$store.dispatch('compararProdutos', this.produto.codigo)
      },
      addCarrinho : function () {
         this.$store.dispatch('addCarrinho', JSON.parse(JSON.stringify(this.produto)))
      }
   }
}

</script>

<style scoped>

img {
	aspect-ratio: 1 / 1;
	object-fit: contain;
}

@media (min-width: 767px) {
   .carousel-250 {
      min-width: 250px !important;
   }
}

@media (max-width: 767px) {
   button {
      line-height: 2.4 !important;
      font-size: 14px !important;
   }

   .product-item-link {
      font-size: 13px !important;
   }

   .price {
      font-size: 14px !important;
   }
}

.product__action a:hover .fa-check::before {
   content: "\f00d";
}

</style>